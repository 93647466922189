import logo from './logo.jpeg';
import logoU from './logoU.jpg';
import sponsors from './sponsors.jpg';
import './App.css';
import fivepack from './Unfiltered_Pachet_5TokensGlass.jpg';
import tenpack from './Unfiltered_Pachet_10TokensGlass.jpg';
import twenty from './Unfiltered_Pachet_20TokensGlass.jpg';
import insta from './insta.png';
import fb from './fb.png';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

let breweries = [
    {
        name: "Ultima",
        beers: [
            {
                name: "Ramen, băi",
                style: "ramen clone sour",
                abv: "6.6%",
                ingredients: "pho, salt, habanero, cinnamon, MSG",
				url: "https://untappd.com/b/ultima-ramen-bai/5826565"
            },
            {
                name: "Coupage No1",
                style: "barrel-aged imperial stout",
                abv: "10.3%",
                ingredients: "blueberries, cinnamon, tonka",
				url: "https://untappd.com/b/ultima-coupage-no1/3682384"
            }
        ]
    },
    {
        name: "Sofia Electric Brewing",
        beers: [
            {
                name: "Overflow",
                style: "juicy NEIPA",
                abv: "6.7%",
                ingredients: "dry hopped with 15gr/L of Citra, Mosaic, Amarillo",
				url: "https://untappd.com/b/sofia-electric-brewing-overflow/3892225"
            },
            {
                name: "Vishna Fruited Sour",
                style: "fruited sour",
                abv: "5.5%",
                ingredients: "sour cherries",
				url: "https://untappd.com/b/sofia-electric-brewing-vishna-vishna/5936340"
            }
        ]
    },
    {
        name: "Sima",
        beers: [
            {
                name: "Superdelicious",
                style: "NZ IPA",
                abv: "6.7%",
                ingredients: "Riwaka, Nelson Sauvin",
				url: "https://untappd.com/b/sima-brewing-superdelicious/5956855"
            },
            {
                name: "Simple Point",
                style: "sour",
                abv: "7%",
                ingredients: "apricot, basil, tonka",
				url: "https://untappd.com/SIMA_brewing/"
            }
        ]
    },
    {
        name: "Sakiskes",
        beers: [
            {
                name: "Lithuanian Pilsner",
                style: "pilsner",
                abv: "4.7%",
                ingredients: "rich and toasty",
				url: "https://untappd.com/b/sakiskes-brewery-sakiskiu-alus-lithuanian-pilsner/4260869"
            },
            {
                name: "Hop Series Hazy IPA",
                style: "hazy IPA",
                abv: "5.5%",
                ingredients: "Citra, Mosaic, Galaxy",
				url: "https://untappd.com/b/sakiskes-brewery-sakiskiu-alus-hop-series-hazy-ipa/5924959"
            }
        ]
    },
    {
        name: "Ophuissa",
        beers: [
            {
                name: "Rosa",
                style: "Smoothie Sour",
                abv: "5%",
                ingredients: "apricot, guava, mango",
				url: "https://untappd.com/b/ophiussa-rosa/5996516"
            },
            {
                name: "Maple Decadence",
                style: "imperial pastry porter",
                abv: "14%",
                ingredients: "maple syrup, Tahitian vanilla",
				url: "https://untappd.com/b/ophiussa-brewing-co-maple-decadence/5828286"
            }
        ]
    },
    {
        name: "OneTwo",
        beers: [
            {
                name: "Fig Honey Jasmine Imperial Stout",
                style: "imperial stout",
                abv: "10%",
				url: "https://untappd.com/b/onetwo-fig-honey-lavender-imperial-stout/4600326"
            },
            {
                name: "Ginger Elderflower",
                style: "sour",
				ingredients: "ginger, elderflower",
                abv: "5%",
				url: "https://untappd.com/OneTwoTM"
            }
        ]
    },
    {
        name: "Nurme",
        beers: [
            {
                name: "Crimson Horizon",
                style: "American IPA",
                abv: "6.5%",
                ingredients: "Sabro, Idaho, Chinook",
				url: "https://untappd.com/b/nurme-crimson-horizon/5962200"
            },
            {
                name: "Pils",
                style: "German Pilsner",
                abv: "4.9%",
                ingredients: "German Mittelfrüh hops",
				url: "https://untappd.com/b/nurme-pils/3961103"
            }
        ]
    },
    {
        name: "Noah",
        beers: [
            {
                name: "Poet of Pale Ale",
                style: "pale ale",
                abv: "5.5%",
                ingredients: "Mosaic, Citra",
				url: "https://untappd.com/b/ignat-brewery-bere-noah-noah-poet-of-pale-ale/3022162"
            },
            {
                name: "Heaven Crashers",
                style: "DDH NEIPA",
                abv: "7%",
                ingredients: "Nectaron, Mosaic",
				url: "https://untappd.com/b/ignat-brewery-bere-noah-heaven-crashers/5964797"
            }
        ]
    },
    {
        name: "Metalhead",
        beers: [
            {
                name: "Aesthetics of Haze",
                style: "NEIPA",
                abv: "6%",
                ingredients: "Dynaboost, Hyperboost",
				url: "https://untappd.com/b/metalhead-aesthetics-of-haze/5995685"
            },
            {
                name: "Dusk And Her Embrace",
                style: "imperial stout",
                abv: "11%",
                ingredients: "blueberry, maple syrup, coconut, vanilla",
				url: "https://untappd.com/b/metalhead-dusk-and-her-embrace/5711617"
            }
        ]
    },
    {
        name: "Maktoob",
        beers: [
            {
                name: "Basil Instinct",
                style: "sour",
                abv: "4%",
                ingredients: "apricot, basil, cookie dough",
				url: "https://untappd.com/Maktoob/beer"
            },
            {
                name: "Well, Beer is Technically Food",
                style: "DDH NEIPA",
                abv: "8.1%",
				url: "https://untappd.com/b/maktoob-brewing-well-actually-beer-is-technically-food/5975001"
            }
        ]
    },
    {
        name: "Mad Scientist",
        beers: [
            {
                name: "Tokyo Coffee",
                style: "Berliner Weisse",
                abv: "6%",
                ingredients: "yuzu, Casino Mocca special coffee",
				url: "https://untappd.com/b/mad-scientist-tokyo-coffee/5969111"
            },
            {
                name: "Marilyn Manderson",
                style: "barrel-aged wheat wine",
                abv: "9.8%",
                ingredients: "bergamot",
				url: "https://untappd.com/b/mad-scientist-marilyn-manderson/5410383"
            }
        ]
    },
    {
        name: "Kykao",
        beers: [
            {
                name: "West Coast IPA",
                style: "IPA",
                abv: "7%",
                ingredients: "Cashmere, Talus",
				url: "https://untappd.com/b/kykao-handcrafted-west-coast-ipa/4255517"
            },
            {
                name: "Kyclus",
                style: "sour",
                abv: "8%",
                ingredients: "cherry, mahleb, mastic",
				url: "https://untappd.com/b/kykao-handcrafted-kyklus-sour-ale-experiment-2/5523629"
            }
        ]
    },
    {
        name: "Ironic",
        beers: [
            {
                name: "Not My Cup of Tea",
                style: "pale ale",
                abv: "4.5%",
                ingredients: "white tea",
				url: "https://untappd.com/b/ironic-brewery-not-my-cup-of-tea/4166675"
            },
            {
                name: "Mexican Lager",
                style: "lager",
                abv: "5%",
				url: "https://untappd.com/b/ironic-brewery-mexican-lager/5631061"
            }
        ]
    },
    {
        name: "Hop Hooligans",
        beers: [
            {
                name: "Mermaid Margarita Sour",
                style: "sour",
                abv: "5%",
                ingredients: "lemon zest, orange zest, coconut",
                collab: "Clandestin",
				url: "https://untappd.com/b/hop-hooligans-mermaid-margarita/6003570"
            },
            {
                name: "Rainbow Eyes",
                style: "sour",
                abv: "6%",
                ingredients: "mango, raspberry, ice cream",
				url: "https://untappd.com/b/hop-hooligans-rainbow-eyes-mango-raspberry-ice-cream-sour/5829376"
            }
        ]
    },
    {
        name: "Dois Corvos",
        beers: [
            {
                name: "YouTopia",
                style: "DDH NEIPA",
                abv: "6%",
				url: "https://untappd.com/b/dois-corvos-youtopia/5980912"
            },
            {
                name: "Apricot Subplot",
                style: "Berliner Weisse",
                abv: "4.2%",
                ingredients: "apricots",
                aging: "aged in oak barrels for 2-4 years",
				url: "https://untappd.com/b/dois-corvos-apricot-subplot/5572420"
            }
        ]
    },
    {
        name: "Cohones",
        beers: [
            {
                name: "Skal",
                style: "dry-hopped lager",
                abv: "5%",
                ingredients: "Atanum",
                collab: "Brewsky",
				url: "https://untappd.com/b/cohones-brewery-skal/5997828"
            },
            {
                name: "Go Art Yourself: Vlad Tapas",
                style: "DDH NEIPA",
                abv: "6.5%",
                ingredients: "Galaxy, Vic Secret, Bru-1",
				url: "https://untappd.com/Cohones_Brewery"
            }
        ]
    },
    {
        name: "Clandestin",
        beers: [
            {
                name: "Goth Save the Queen",
                style: "NEIPA",
                abv: "5.9%",
                ingredients: "Eclipse, Citra",
				url: "https://untappd.com/b/clandestin-beer-goth-save-the-queen/5973400"
            },
            {
                name: "Stinky",
                style: "Dank IPA",
                abv: "5.6%",
                ingredients: "Strata, Galaxy, Citra",
                collab: "Hop Hooligans",
				url: "https://untappd.com/b/clandestin-beer-stinky/5973356"
            }
        ]
    },
    {
        name: "Casa Despi",
        beers: [
            {
                name: "Brut Apple",
				abv: "7%",
                style: "Cider",
				url: "https://untappd.com/b/casa-despi-genesis-brut/3946577"
            },
            {
                name: "Brut Apple and Pear",
				abv: "7%",
                style: "Cider",
				url: "https://untappd.com/b/casa-despi-genesis-brut-mere-si-pere/4386319"
            }
        ]
    },
    {
        name: "Blackout",
        beers: [
            {
                name: "Weller BA Prey On Society",
                style: "barrel-aged barley wine",
                abv: "12%",
                ingredients: "roasted chestnuts, vanilla",
				url: "https://untappd.com/b/blackout-brewing-weller-ba-prey-on-society/5996403"
            },
            {
                name: "Fragments of Mind",
                style: "blended barrel-aged stout",
                abv: "12%",
                ingredients: "everything",
				url: "https://untappd.com/b/blackout-brewing-fragments-of-minds/6014764"
            }
        ]
    },
    {
        name: "Bereta",
        beers: [
            {
                name: "Bring Out the Olives!",
                style: "New England IPA",
                abv: "6%",
                collab: "Sureshot Brewing",
				url: "https://untappd.com/b/sureshot-brewing-bring-out-the-olives/5939425"
            },
            {
                name: "Wedding Crash",
                style: "heavily fruited sour",
                abv: "3.4%",
                ingredients: "mango, spinach, mint",
                collab: "Zythologist",
				url: "https://untappd.com/b/bereta-brewing-co-wedding-crash/5956380"
            }
        ]
    },
    {
        name: "Anagram",
        beers: [
            {
                name: "Can Can",
                style: "hazy IPA",
                abv: "6%",
                ingredients: "Nectaron, Citra, Mosaic",
				url: "https://untappd.com/b/anagram-brewery-can-can/5794460"
            },
            {
                name: "Venus in Furs",
                style: "Wild ale",
				ingredients: "Apricot",
                aging: "Barrel-aged in Feteasca Neagra wine barrels",
				url: "https://untappd.com/AnagramBrewing/beer"
            }
        ]
    },
    {
        name: "Van Moll",
        beers: [
            {
                name: "Digital Twin",
                style: "hazy IPA",
                abv: "6%",
                collab: "Finback Brewery",
				url: "https://untappd.com/b/van-moll-digital-twin/5938210"
            },
            {
                name: "Three Amigos",
                style: "Belgian strong dark ale",
                abv: "9.5%",
		collab: "100 Watt & Stadsbrouwerij 013",
                ingredients: "Barrel aged",
		url: "https://untappd.com/b/van-moll-three-amigos/6016189"
            }
        ]
    },
	{
		name: " 3bir",
		beers: [
			{
				name: "Akira Senshi",
				style: "Double NEIPA",
				abv: "7.6%",
				url: "https://untappd.com/b/3bir-akira-senshi/5712748"
			},
			{
				name: 'Sunday Morning Sour',
				style: 'Sour',
				abv: '3.2%',
				ingredients: 'Orange, Blackcurrant',
				url: "https://untappd.com/b/3bir-sunday-morning-sour/5876125"
			}
		]
	}
];


function App() {
	// State to track checked beers
    const [checkedBeers, setCheckedBeers] = useState({});

    // Load checked beers from local storage on component mount
    useEffect(() => {
		// Capitalize ingredients
		breweries = breweries.map(brewery => {
			brewery.beers = brewery.beers.map(beer => {
				if (beer.ingredients) {
					beer.ingredients = beer.ingredients.split(',').map(ingredient => ingredient.trim()).map(ingredient => ingredient.charAt(0).toUpperCase() + ingredient.slice(1)).join(', ');
				}
				return beer;
			});
			return brewery;
		});
        const storedBeers = JSON.parse(localStorage.getItem('checkedBeers')) || {};
        setCheckedBeers(storedBeers);
    }, []);

    // Function to handle checkbox change
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const updatedCheckedBeers = { ...checkedBeers, [name]: checked };
        setCheckedBeers(updatedCheckedBeers);
        localStorage.setItem('checkedBeers', JSON.stringify(updatedCheckedBeers));
    };

    // Function to check if a beer is checked
    const isBeerChecked = (beerName) => {
        return checkedBeers[beerName] || false;
    };

	return (
		<Router>
			<Routes>
				<Route path="/" element={
					<div className="App">
						<div id='socials'>
							<a href='https://www.instagram.com/unfiltered.festival/' className="App-link">
								<img src={insta} className="Social" alt="Instagram"/>
							</a>
							<a href='https://www.facebook.com/events/1579873589538333' className="App-link">
								<img src={fb} className="Social" alt="Facebook"/>
							</a>
						</div>
						<img src={logo} className="App-logo" alt="logo"/>
						<div id='products'>
							<a
								className="App-link"
								href="https://one-two.ro/en/packs/187-unfiltered-taster-pack.html"
								target="_blank"
								rel="noopener noreferrer"
							>	
								<span>Taster Pack</span>
								<img src={fivepack} className="FivePack" alt="Unfiltered 5 tokens pack"/>
							</a>
							<a
								className="App-link"
								href="https://one-two.ro/en/packs/188-unfiltered-explorer-pack.html"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span>Explorer Pack</span>
								<img src={tenpack} className="TenPack" alt="Unfiltered 10 tokens pack"/>
							</a>
							<a
								className="App-link"
								href="https://one-two.ro/en/packs/189-unfiltered-connoisseur-pack.html"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span>Connoisseur Pack</span>
								<img src={twenty} className="Twenty" alt="Unfiltered 20 tokens pack"/>
							</a>
						</div>
						<img src={sponsors} className="Sponsors" alt="Unfiltered partners"/>
					</div>}/>
				<Route path="/taplist" element={
					<div className="Taplist">
						{/* Taplist - Checklist */}
						<img src={logoU} alt="Unfiltered." />
						<h1>TAPLIST</h1>
						{breweries.sort((a,b) => {
							// Custom sort logic
							if (a.name < b.name) return -1;
							if (a.name > b.name) return 1;
							return 0;
						}).map(brewery => (
							<div key={brewery.name}>
								<h2>{brewery.name}</h2>
								<ul>
									{brewery.beers.map(beer => (
										<li key={beer.name}>
											<div>
												<div><a href={beer.url} target='_blank' rel="noreferrer" style={{ textDecoration: 'underline', fontSize: 18 }}>{beer.name}</a></div>
												<div><span style={{ textTransform: 'uppercase' }}>{beer.style}</span></div>
												{beer.collab ? <div>Collab: {beer.collab}</div> : ''}
												{beer.aging ? <div>{beer.aging}</div> : ''}
												<div style={{ fontWeight: 800 }}>{beer.abv}</div>
												<div>{beer.ingredients}</div>
											</div>
											<div>
												{/* Checkbox */}
                                                <input
                                                    type="checkbox"
                                                    id={beer.name}
                                                    name={beer.name}
                                                    value={beer.name}
                                                    checked={isBeerChecked(beer.name)}
                                                    onChange={handleCheckboxChange}
                                                />
											</div>
										</li>
									))}
								</ul>
							</div>
						))}
					</div>
				} />
			</Routes>
		</Router>
		
	);
}

export default App;
